export const BLOOD_TYPE = {
  First: 'pages.account.anketa.blood-type.first', // $t('footer.background-information')
  Second: 'pages.account.anketa.blood-type.second',
  Third: 'pages.account.anketa.blood-type.third',
  Fourth: 'pages.account.anketa.blood-type.fourth'
};

export const RH_FACTOR = {
  Positive: 'pages.account.anketa.positive',
  Negative: 'pages.account.anketa.negative'
};

export const FIELDS = {
  height: {
    title: 'pages.account.anketa.hight-cm',
    placeholder: 'pages.account.anketa.specify-hight'
  },
  weight: {
    title: 'pages.account.anketa.weight-kg',
    placeholder: 'pages.account.anketa.specify-weight'
  },
  chronicDiseases: {
    title: 'pages.account.anketa.chronical-diseases'
  },
  allergies: {
    title: 'pages.account.anketa.allergies'
  },
  bloodType: {
    title: 'pages.account.anketa.blood-type-title'
  },
  rhFactor: {
    title: 'pages.account.anketa.rh-title'
  },
  surgeries: {
    title: 'pages.account.anketa.operations'
  },
  permanentMedications: {
    title: 'pages.account.anketa.permanent-medications'
  }
  // WOMEN
  // menstrualCycleRegularity: {
  //   title: 'pages.account.anketa.menstrual-period'
  // },
  // menstrualCycleDuration: {
  //   title: 'pages.account.anketa.duration-menstrual-period'
  // },
  // isPregnant: {
  //   title: 'pages.account.anketa.is-pregnancy-now'
  // },
  // currentPregnancyInWeeks: {
  //   title: 'pages.account.anketa.due-weeks'
  // },
  // pregnancyResult: {
  //   title: 'pages.account.anketa.what-result-pregnancy'
  // },
  // pregnancyResultWeek: {
  //   title: 'pages.account.anketa.week'
  // },
  //
  // hadPregnanciesInThePast: {
  //   title: 'pages.account.anketa.previous-pregnancies'
  // },
  // hadAbortions: {
  //   title: 'pages.account.anketa.abortions-earlier'
  // },
  // lastAbortionReason: {
  //   title: 'pages.account.anketa.reason-last-abortion'
  // }
};

/*
 * isWoman - делаем выборку из medicalQuestionnaireForWomenRequest(только для женщин)
 * constValue - значение нужно брать из константы
 * sex - Пол пользователя. Если пациент женщина, то блоки isWoman не показываем
 */
export const BLOCKS_FIELDS_VIEW = [
  [
    { field: 'allergies' },
    { field: 'chronicDiseases' },
    { field: 'permanentMedications' },
    { field: 'surgeries' }
  ]
  // [
  //   {
  //     isWoman: true,
  //     field: 'menstrualCycleRegularity',
  //     constValue: MENSTRUAL_CYCLE_REGULARITY
  //   },
  //   { isWoman: true, field: 'menstrualCycleDuration' },
  //   { isWoman: true, field: 'isPregnant' },
  //   { isWoman: true, field: 'currentPregnancyInWeeks' },
  //   {
  //     isWoman: true,
  //     field: 'pregnancyResult',
  //     constValue: PREGNANCY_RESULT
  //   },
  //   { isWoman: true, field: 'pregnancyResultWeek' },
  //   { isWoman: true, field: 'hadPregnanciesInThePast' },
  //   { isWoman: true, field: 'hadAbortions' },
  //   {
  //     isWoman: true,
  //     field: 'lastAbortionReason',
  //     constValue: ABORTION_REASON
  //   }
  // ]
];

export const LIST_RADIO_BOOLEAN = [
  { title: 'common.yes-or-no.yes', id: true },
  { title: 'common.yes-or-no.no', id: false }
];
