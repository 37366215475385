<template>
  <div
    id="vc-support"
    class="md:absolute md:top-full left-0 md:mt-4 md:w-[400px] md:h-[600px] md:rounded-lg bg-v-ui-background-monochrome-0 shadow-xl overflow-hidden h-full w-full fixed top-0 z-[100]"
  >
    <support-header @close="close" />
    <support-chat
      :groups-messages="groupsMessages"
      @setMessage="setMessage"
      @filesCount="setFilesCount"
    />
  </div>
</template>

<script>
import { scrollToBottom } from '~/services/support-chat/support.helpers';
import SupportHeader from '@/components/domains/support/ui/SupportHeader';
import SupportChat from '@/components/domains/support/ui/chat/SupportChat';

export default {
  name: 'VcSupport',
  components: {
    SupportHeader,
    SupportChat
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoadPreviewAppeal: false,
      groupsMessages: [],
      filesCount: 0,
      isLoadPreview: false
    };
  },
  watch: {
    groupsMessages() {
      if (this.isOpen && !this.isLoadPreview) {
        if (this.groupsMessages.chatId)
          try {
            this.$chatService.markAsRead(this.groupsMessages.chatId);
          } catch (e) {
            console.log(e);
          }
        this.$nextTick(() => scrollToBottom('support-chat'));
      }
      this.isLoadPreview = false;
    }
  },
  async mounted() {
    await this.init();
    this.$chatService.readMessage(this.groupsMessages.chatId, () => {
      this.groupsMessages[1].forEach((message) => {
        if (!message.wasRead) {
          message.wasRead = true;
        }
      });
    });
    this.checkScroll();
  },
  methods: {
    setFilesCount(count) {
      this.filesCount = count;
      this.$supportChatService.filesCount = count;
    },
    async init() {
      this.positionContent();

      const { groupsMessages } = await this.$supportChatService.init(
        this.handleMessage,
        this.handleEditMessage
      );
      groupsMessages[groupsMessages.length - 1].forEach((message) => {
        message.isPreview = true;
      });
      this.groupsMessages = groupsMessages;
      this.$nextTick(() => scrollToBottom('support-chat'));
      this.checkScroll();
    },
    checkScroll() {
      setTimeout(async () => {
        await this.getPrevAppeal();
        const el = document.getElementById('support-chat');

        el.addEventListener('scroll', async (e) => {
          if (!this.isLoadPreviewAppeal) {
            const scroll = el.scrollTop;
            const height = el.scrollHeight - el.clientHeight;

            if (height - scroll >= height - 30) {
              this.isLoadPreviewAppeal = true;
              this.isLoadPreview = true;
              const messages = await this.$supportChatService.loadNextAppeal();
              if (messages) {
                const chatId = this.groupsMessages.chatId;

                const list = [messages[0], ...this.groupsMessages];
                list.chatId = chatId;
                this.groupsMessages = list;

                this.$nextTick(() => {
                  document.getElementById('support-chat').scrollTop =
                    document.getElementById('messages-group-0').offsetHeight -
                    100;
                });
              }

              setTimeout(() => (this.isLoadPreviewAppeal = false), 1000);
            }
          }
        });
      }, 1000);
    },
    async getPrevAppeal() {
      const el = document.getElementById('support-chat');
      if (!this.isLoadPreviewAppeal) {
        const scroll = el.scrollTop;
        const height = el.scrollHeight - el.clientHeight;

        if (height - scroll >= height - 30) {
          this.isLoadPreviewAppeal = true;
          this.isLoadPreview = true;
          const messages = await this.$supportChatService.loadNextAppeal();
          if (messages) {
            const chatId = this.groupsMessages.chatId;

            const list = [messages[0], ...this.groupsMessages];
            list.chatId = chatId;
            this.groupsMessages = list;

            this.$nextTick(() => {
              document.getElementById('support-chat').scrollTop =
                document.getElementById('messages-group-0').offsetHeight - 100;
            });
          }

          setTimeout(() => (this.isLoadPreviewAppeal = false), 1000);
        }
      }
    },
    positionContent() {
      /*
       * Позиция окна на десктоп версии
       */
      const mobileSize = 990;

      const chat = document.getElementById('vc-support');
      const posChat = chat.getBoundingClientRect().right;
      const innerWidth = window.innerWidth;

      if (posChat > innerWidth && innerWidth > mobileSize) {
        chat.style.left = `${innerWidth - posChat - 30}px`;
      }
    },
    handleMessage(messages) {
      this.groupsMessages = messages;
    },
    handleEditMessage(messages) {
      this.groupsMessages = [...messages];
    },
    close() {
      this.$emit('close');
    },
    setMessage(message) {
      if (message.selected === 'Delete') {
        const index = this.groupsMessages[1].findIndex(
          (item) => item.id === message.messageId
        );
        this.groupsMessages[1].splice(index, 1);
      }
      if (message.selected === 'Repeat') {
        const index = this.groupsMessages[1].findIndex(
          (item) => item.id === message.messageId
        );
        const temp = Object.assign({}, this.groupsMessages[1][index]);
        temp.isError = false;
        temp.isSending = true;
        this.groupsMessages[1].splice(index, 1);
        this.$supportChatService.sendMessage(temp, false);
      }
    }
  }
};
</script>
